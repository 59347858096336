<template>
  <div>
    <Card :bordered="true" :style="{background:'#f4f4f4',border:0}">
      <a href="#" class="avatar"><img src="../assets/image/avatar.jpg" alt=""></a>
      <div style="line-height: 30px">
      <h2>守候</h2>
      <p>攀登科学高峰，就像登山运动员攀登珠穆朗玛峰一样，要克服无数艰难险阻，懦夫和懒汉是不可能享受到胜利的喜悦和幸福的</p>
      </div>
    </Card>
    <Divider/>
  </div>

</template>

<script>
export default {
  name: "Introduce"
}
</script>

<style scoped>

.avatar {
  border-bottom: 0;
  display: inline-block;
  margin: 0 0 1em 0;
  overflow: hidden;
  position: relative;
}

.avatar:before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200px' height='200px' viewBox='0 0 100 100' preserveAspectRatio='none' zoomAndPan='disable'%3E%3Cpolygon points='0,0 100,0 100,25 50,0 0,25' style='fill:%23f4f4f4' /%3E%3Cpolygon points='0,100 100,100 100,75 50,100 0,75' style='fill:%23f4f4f4' /%3E%3C/svg%3E");
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  transition: color 0.2s ease;
  box-sizing: border-box;
}

img {
  display: block;
  margin-bottom: -0.75em;
  width: 8.5em;
}

</style>