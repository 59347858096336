import request from '@/utils/request'
//提交留言
export function postMessage(message) {
    return request({
        url: '/blog/front/message',
        method: 'post',
        params: message
    })
}

/**
 * 提交联系记录
 * @param record
 * @returns {AxiosPromise}
 */
export function submitContentRecord(record) {
    return request({
        url: '/blog/front/submitContentRecord',
        method: 'post',
        params: record
    })
}

//查询文章列表
export function queryArticles(pageVo) {
    return request({
        url: 'blog/front/articles',
        method: 'get',
        params: pageVo
    })
}

//根据标签ID查询文章列表
export function queryArticlesByLabelId(pageVo) {
    return request({
        url: 'blog/front/articles',
        method: 'get',
        params: pageVo
    })
}

//查询热点文章列表
export function queryHotArticles() {
    return request({
        url: 'blog/front/hotArticles',
        method: 'get'
    })
}

//查询热点文章列表
export function starArticleRequest(p) {
    return request({
        url: 'blog/front/starArticle',
        method: 'post',
        params: p
    })
}




//查询标签列表
export function queryFrontLabels() {
    return request({
        url: 'blog/front/labels',
        method: 'get'
    })
}

//查询友情链接列表
export function queryFriendLinks() {
    return request({
        url: 'blog/front/friendLinks',
        method: 'get'
    })
}

//查询文章评论
export function queryArticleComment(p) {
    return request({
        url: 'blog/front/articleComments',
        method: 'get',
        params: p
    })
}
//查询文章详情
export function queryArticleDetail(p) {
    return request({
        url: 'blog/front/articleDetail',
        method: 'get',
        params: p
    })
}
//查询文章详情
export function queryAllLabels() {
    return request({
        url: 'blog/admin/labels',
        method: 'get'
    })
}
//添加标签
export function addLabel(param){
    return request({
        url: 'blog/admin/addLabel',
        method: 'post',
        params: param
    })
}
//删除标签
export function removeLabel(id){
    return request({
        url: 'blog/admin/deleteLabel',
        method: 'post',
        params: {id:id}
    })
}
//修改标签
export function updateLabel(param){
    return request({
        url: 'blog/admin/updateLabel',
        method: 'post',
        params: param
    })
}




//查询文章详情
export function submitComment(data) {
    return request({
        url: 'blog/front/submitComment',
        method: 'post',
        params: data
    })
}

//查询当前登录用户
export function getUser() {
    return request({
        url: 'blog/admin/getUser',
        method: 'get'
    })
}

//查询当前登录用户
export function logout() {
    return request({
        url: 'blog/logout',
        method: 'get'
    })
}

//删除文章
export function deleteArticle(id) {
    return request({
        url: 'blog/admin/deleteArticle',
        method: 'post',
        params:{id:id}
    })
}
//修改状态
export function changeStatus(id,status) {
    return request({
        url: 'blog/admin/publishOrUnpublish',
        method: 'post',
        params:{id:id,status:status}
    })
}

/**
 * 删除图片
 * @param imageUrl
 * @returns {AxiosPromise}
 */
export function deleteImage(imageUrl) {
    return request({
        url: 'blog/admin/deleteUpload',
        method: 'post',
        params:{imageUrl:imageUrl}
    })
}

/**
 * 创建文章
 * @param param
 * @returns {AxiosPromise}
 */
export function createArticle(param) {
    return request({
        url: 'blog/admin/createArticle',
        method: 'post',
        data:param
    })
}

/**
 * 修改文章
 * @param param
 * @returns {AxiosPromise}
 */
export function updateArticle(param) {
    return request({
        url: 'blog/admin/updateArticle',
        method: 'post',
        data:param
    })
}

/**
 * 分页查询
 * @param id
 * @param status
 * @returns {AxiosPromise}
 */
export function pageComments(pageNo,pageSize) {
    return request({
        url: 'blog/admin/pageComments',
        method: 'get',
        params:{pageNo:pageNo,pageSize:pageSize}
    })
}

/**
 * 分页查询
 * @param id
 * @param status
 * @returns {AxiosPromise}
 */
export function pageMessageInfo(pageNo,pageSize) {
    return request({
        url: 'blog/admin/pageMessageInfo',
        method: 'get',
        params:{pageNo:pageNo,pageSize:pageSize}
    })
}

/**
 * 分页查询
 * @param id
 * @param status
 * @returns {AxiosPromise}
 */
export function pageContactRecord(pageNo,pageSize) {
    return request({
        url: 'blog/admin/pageContactRecord',
        method: 'get',
        params:{pageNo:pageNo,pageSize:pageSize}
    })
}
