<template>
  <div style="width: 100%">
    <Introduce/>
    <Card>
      <p slot="title">生活点滴</p>
      <div>
        <ShowSwiper/>
      </div>
    </Card>
    <Card>
      <p slot="title">
        技术标签
      </p>
      <p class="labels">
        <a :href="'/search.html?type=label_query&labelId='+item.id" v-for="item in labels" :key="item.id" :style="{'font-size':item.size+'px'}">{{ item.label }}</a>
      </p>
    </Card>
    <Card :bordered="false" :style="{background:'#f4f4f4',border:0,marginTop:50}  ">
      <p slot="title">热门阅读</p>
      <div>
        <ul style="list-style: none">
          <li v-for="item in hotlink" :key="item.id">
            <HotLink :title="item.title" :imageUrl="item.imageUrl+'?x-oss-process=image/resize,w_50,h_50,m_fixed'"
                     :linkUrl="'/article.html?id='+item.id"/>
          </li>
        </ul>
      </div>
    </Card>
    <Card>
      <p slot="title">留言板</p>
      <div>
        <Input v-model="message" res maxlength="100" show-word-limit type="textarea" placeholder="留下你的建议吧..."
               style="width: 100%" :rows="5" :autosize="{maxRows: 5,minRows: 5}" ref="liveMessage"/>
      </div>
      <div class="sub-div">
        <button type="button" @click="submitMessage">提交</button>
      </div>
    </Card>

    <Card :bordered="false" :style="{background:'#f4f4f4',border:0,marginTop:50}  ">
      <p slot="title">友情链接</p>
      <div>
        <ul style="list-style: none">
          <li v-for="item in friendsLink" :key="item.id">
            <HotLink :title="item.title" :linkUrl="item.linkUrl"/>
          </li>
        </ul>
      </div>
    </Card>
  </div>

</template>

<script>
import HotLink from "@/components/HotLink"
import {postMessage, queryHotArticles, queryFriendLinks, queryFrontLabels} from '@/api/api';
import Introduce from "@/components/Introduce";
import ShowSwiper from "@/components/ShowSwiper";

export default {
  name: "SideBar",
  components: {Introduce, HotLink,ShowSwiper},
  data: function () {
    return {
      message: "",
      hotlink: [],
      friendsLink: [],
      labels: []
    }
  },
  mounted: function () {
    this.loadHotLink();
    this.loadFriendLink();
    this.loadAllLabels();
  },
  methods: {
    submitMessage() {
      if (this.message == '') {
        this.$Message.warning("请输入留言信息");
        this.$refs.liveMessage.focus();
        return;
      }
      postMessage({message: this.message}).then(response => {
        this.message = "";
        console.log(response);
        this.$Message.success('留言成功！');
      });
    },
    loadHotLink() {
      queryHotArticles().then(response => {
        this.hotlink = response.biz;
      });
    },
    loadFriendLink() {
      queryFriendLinks().then(response => {
        this.friendsLink = response.biz;
      });
    },
    loadAllLabels() {
      queryFrontLabels().then(response => {
        this.labels = response.biz;
      });
    }
  }
}
</script>

<style scoped>

.image {
  overflow: hidden;
  width: 100%;
  max-height: 200px;
  border: 0;
  display: inline-block;
  position: relative;
}

a {
  transition: color 0.2s ease, border-bottom-color 0.2s ease;
  color: inherit;
  text-decoration: none;
}

.labels {
  overflow-wrap: break-word;
}

.labels a {
  margin: 8px;
}

.ivu-card {
  margin-top: 10px !important;
}

input[type="submit"],
input[type="reset"],
input[type="button"],
button,
.button {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -moz-transition: background-color 0.2s ease, box-shadow 0.2s ease, color 0.2s ease;
  -webkit-transition: background-color 0.2s ease, box-shadow 0.2s ease, color 0.2s ease;
  -ms-transition: background-color 0.2s ease, box-shadow 0.2s ease, color 0.2s ease;
  transition: background-color 0.2s ease, box-shadow 0.2s ease, color 0.2s ease;
  background-color: transparent;
  border: 0;
  box-shadow: inset 0 0 0 1px rgba(160, 160, 160, 0.3);
  color: #3c3b3b !important;
  cursor: pointer;
  display: inline-block;
  font-family: "Raleway", Helvetica, sans-serif;
  font-size: 0.6em;
  font-weight: 800;
  height: 2.8125em;
  letter-spacing: 0.25em;
  line-height: 1.8125em;
  padding: 0 1.5em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
}

input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover,
button:hover,
.button:hover {
  box-shadow: inset 0 0 0 1px #2ebaae;
  color: #2ebaae !important;
}

input[type="submit"]:hover:active,
input[type="reset"]:hover:active,
input[type="button"]:hover:active,
button:hover:active,
.button:hover:active {
  background-color: rgba(46, 186, 174, 0.05);
}

input[type="submit"]:before, input[type="submit"]:after,
input[type="reset"]:before,
input[type="reset"]:after,
input[type="button"]:before,
input[type="button"]:after,
button:before,
button:after,
.button:before,
.button:after {
  color: #aaaaaa;
  position: relative;
}

input[type="submit"]:before,
input[type="reset"]:before,
input[type="button"]:before,
button:before,
.button:before {
  left: -1em;
  padding: 0 0 0 0.75em;
}

input[type="submit"]:after,
input[type="reset"]:after,
input[type="button"]:after,
button:after,
.button:after {
  left: 1em;
  padding: 0 0.75em 0 0;
}

input[type="submit"].fit,
input[type="reset"].fit,
input[type="button"].fit,
button.fit,
.button.fit {
  display: block;
  margin: 0 0 1em 0;
  width: 100%;
}

input[type="submit"].big,
input[type="reset"].big,
input[type="button"].big,
button.big,
.button.big {
  font-size: 0.7em;
  padding: 0 3em;
}

input[type="submit"].small,
input[type="reset"].small,
input[type="button"].small,
button.small,
.button.small {
  font-size: 0.5em;
}

input[type="submit"].disabled, input[type="submit"]:disabled,
input[type="reset"].disabled,
input[type="reset"]:disabled,
input[type="button"].disabled,
input[type="button"]:disabled,
button.disabled,
button:disabled,
.button.disabled,
.button:disabled {
  -moz-pointer-events: none;
  -webkit-pointer-events: none;
  -ms-pointer-events: none;
  pointer-events: none;
  color: rgba(160, 160, 160, 0.3) !important;
}

input[type="submit"].disabled:before, input[type="submit"]:disabled:before,
input[type="reset"].disabled:before,
input[type="reset"]:disabled:before,
input[type="button"].disabled:before,
input[type="button"]:disabled:before,
button.disabled:before,
button:disabled:before,
.button.disabled:before,
.button:disabled:before {
  color: rgba(160, 160, 160, 0.3) !important;
}

.sub-div {
  padding-top: 5px;
  text-align: right;
}
</style>