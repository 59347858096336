<template>

  <Row>
    <div style="height: 40px;line-height: 40px;padding: 2px">
      <div class="image" v-if="imageUrl != null">
        <img :src="imageUrl" style="width: 40px;height: 40px"/>
      </div>
      <div class="link">
        <a class="title-span" :href="linkUrl" target="_self">{{ title }}</a>
      </div>
    </div>
  </Row>

</template>

<script>
export default {
  name: "HotLink",
  props: ['imageUrl', 'linkUrl', 'title'],
  computed: {
    titleSpan() {
      return (this.imageUrl === null) ? 24 : 20;
    }
  }
}
</script>

<style scoped>
div {
  display: inline-block;
  overflow: hidden;
  position: relative;
}

img {
  position: relative;
  min-width: 40px;
  min-height: 40px;
}

a {
  text-decoration: none;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  font-size: 100%;
  color: #515a6e;
  line-height: 40px;
}

.link {
  float: right;
  padding-left: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow:ellipsis;
  max-width: 204px;
}

a:hover {
  border-bottom-color: transparent;
  color: #2ebaae !important;
}

a.title-span {
  font-size: 0.7em;
  margin-top: 0.125em;
  color: #3c3b3b;
  font-family: "Microsoft Yahei", Tahoma, Arial, sans-serif;
  font-weight: 800;
  letter-spacing: 0.25em;
  line-height: 1.65;
  margin: 0 0 1em 0;
  text-transform: uppercase;
}
</style>