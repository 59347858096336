<template>
      <div class="copy-right" >
          <span>
            © 2021 liyasoftware. All rights reserved. Designed by
            <a href="#" rel="nofollow">清心守候</a>
            </span>
        <div>
          <span><a href="statement.html">网络声明</a></span>
          <span>|</span>
          <span><a href="privacy.html">隐私政策</a></span>
        </div>
        <div>
          <a href="http://beian.miit.gov.cn/">京ICP备2021006741号</a>
        </div>
      </div>
</template>

<script>
export default {
  name: "Copyright"
}
</script>

<style scoped>

  .copy-right{
    text-align: center;
    width: 100%;
    bottom: 0;
    position: inherit;
  }

  a{
    text-decoration: none;
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
    font-size: 100%;
    color: #515a6e;
    margin: 0 2px;
  }

</style>