<template>
  <header id="header">
    <div id="logo">
      <p class="logo-text">清心守候</p>
    </div>
    <nav class="links">
      <ul>
        <li v-for="item in navList" :key="item.id">
          <a :class="active == item.href ? 'active': '' " v-bind:href="item.href">{{item.title}}</a>
        </li>
      </ul>
    </nav>
    <div id="search-div">
      <Input v-model="search"  ref="searchInput" placeholder="搜索文章" @keyup.enter.native="checkSearch">
        <Button slot="append" icon="ios-search" @click="checkSearch"></Button>
      </Input>
    </div>
  </header>
</template>

<script>
export default {
  name: 'FixedNav',
  props: ['active','search'],
  data:function (){
    return {
      navList:[
        {id:0,title:"首页",href:"/index.html",},
        {id:1,title:"技术随笔",href:"tecarticle.html"},
        // {id:2,title:"作品展示",href:"examples"},
        {id:3,title:"生活记忆",href:"lifearticle.html"},
        {id:4,title:"联系留言",href:"contact.html"}
      ],
    }
  },
  mounted() {
    if (this.search != null){
      this.$refs.searchInput.focus();
    }
  },
  methods:{
    checkSearch(){
      if (this.search === null || this.search ===''||this.search === undefined){
        this.$Message.warning("请输入查询关键词");
        this.$refs.searchInput.focus();
      }else {
        window.location.href="/search.html?searchWords="+this.search;
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#header {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  background-color: #ffffff;
  border-bottom: solid 1px rgba(160, 160, 160, 0.3);
  height: 4.5em;
  left: 0;
  line-height: 4.5em;
  top: 0;
  width: 100%;
  z-index: 10000;
  padding-right: 20px;
  min-height: 62px;
}

#header .logo-text{
  display: none;
}

#header a {
  color: inherit;
  text-decoration: none;
}

#header ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

#header ul li {
  display: inline-block;
  padding-left: 0;
}

#header h1 {
  height: inherit;
  line-height: inherit;
  padding: 0 0 0 1em;
  white-space: nowrap;
  margin: 0;
}

#header h1 a {
  font-size: 0.9em;

}


#header .links {
  -moz-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-left: solid 1px rgba(160, 160, 160, 0.3);
  height: inherit;
  line-height: inherit;
  margin-left: 1.5em;
  padding-left: 1.5em;
  text-align: left;
}

#header .links ul li {
  border-left: solid 1px rgba(160, 160, 160, 0.3);
  line-height: 1;
  margin-left: 1em;
  padding-left: 1em;
}

#header .links ul li:first-child {
  border-left: 0;
  margin-left: 0;
  padding-left: 0;
}

#header .links ul li a {
  border-bottom: 0;
  font-family: "Raleway", Helvetica, sans-serif;
  font-size: 0.9em;
  font-weight: 400;
  letter-spacing: 0.25em;
  text-transform: uppercase;
}
#header .links a:hover {
  color: #0edcc4 !important;
}
#header .links a.active{
  color: #0edcc4 !important;
}
#logo{
  background: url("../assets/image/logo.png") no-repeat;
  width: 120px;
  height: 60px;
  overflow: hidden;
  background-size: cover;
}

@media screen and (max-width: 980px) {
  #header .links,
  #header #search-div{
    display: none;
  }

  #logo{
    width: 100%;
    background:none;
  }

  #logo{
    background: url("../assets/image/logo-m.png") no-repeat;
    width: 100%;
    height: 60px;
    overflow: hidden;
    background-size: cover;
  }

}

@media screen and (max-width: 736px) {

  #header {
    height: 2.75em;
    line-height: 2.75em;
  }

  #header h1 {
    padding: 0 0 0 1em;
  }

}

#search-div{
  /*position: absolute;*/
  /*right: 100px;*/
  display: flex;
  align-items: center;
  height: 60px;
}

</style>
