<template>
  <Card :style="{margin: '20px 0'}" :class="'item-card'" >
    <div class="content-item">
      <div class="show-img" @click="showArticle(data.id)">
        <img :src="data.imageUrl+'?x-oss-process=image/resize,w_150,m_fixed'" />
      </div>
      <div class="show-content">
        <div class="show-item title-show" @click="showArticle(data.id)">
          <h2>{{data.title}}</h2>
        </div>
        <div class="show-item desc-show">
          {{data.desc}}
        </div>
        <div class="show-item count-show">
          <span><label>发布时间：</label>{{format(new Date(data.publishTime),'YYYY-MM-DD HH:mm:ss')}} </span>
          <span><label>所属标签：</label>
            <a class="label-link" :href="'/search.html?type=label_query&labelId='+item.id" v-for="item in data.labels" :key="item.id">{{item.label}}</a>
          </span>
          <span>
            <label>阅读量:</label>{{data.readCount}}
          </span>
          <span>
            <label>点赞:</label>{{data.starCount}}
          </span>
          <span>
            <label>评论量：</label>{{data.commentCount}}
          </span>
        </div>
      </div>
    </div>
  </Card>
</template>

<script>
import moment from 'moment';

export default {
name: "ArticleShowItem",
  props:['data'],
  mounted:function (){
  },
  methods:{
    showArticle(id){
      window.location.href="/article.html?id="+id;
    },
    format(date,fmt){
      return  moment(date).format(fmt);
    }
  }
}
</script>

<style scoped>

.content-item{
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
.show-img img{
  width: 80%;
  height: 80%;
}
@media screen and (max-width: 680px){
  .show-img{
    width: 100% !important;
    height: auto !important;
  }
  .count-show{
    display: none !important;
  }
  .show-img img{
    width: 100% !important;
    height: auto !important;
    padding-bottom: 10px;
  }


}
.show-img{
  width: 20%;
}

.show-content{
  width: 80%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.show-item{
  width: 100%;
  display: block;
  line-height: 30px;
  margin: 4px 0;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
}

.count-show{
  height: 30px;
}
.show-item label{
  font-weight: bold;
}
.count-show span{
  margin: 0 5px;
}

.show-item a{
  text-decoration: none;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  font-size: 100%;
  color: #515a6e;
  margin: 0 2px;
}

a.label-link:hover{
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  font-weight: bold;
}

.item-card:hover{
  transform: scale(1.01);
  -webkit-transform: scale(1.01);
}
.title-show,
.show-img{
  cursor: pointer;
}
</style>