<template>
  <div class="show-list-div">
    <div class="article-list"  v-for="item in dataList" :key="item.id">
        <ArticleShowItem :data="item"/>
    </div>
    <div class="page">
      <Page :total="totalCount" @on-change="changePage" prev-text="上一页" next-text="下一页" />
    </div>
  </div>

</template>

<script>

import {queryArticlesByLabelId,queryArticles} from '@/api/api';
import ArticleShowItem from "@/components/ArticleShowItem";

export default {
name: "SearchArticleList",
  components: {ArticleShowItem},
  props:['type','params'],
  data:function (){
      return{
        dataList: [
        ],
        pageNo:1,
        pageSize:10,
        totalCount:0
      }
  },
  mounted:function (){
  console.log("接受："+this.type)
    this.loadArticles();
  },
  methods:{
    loadArticles(){
      let that = this;
      let p = this.params;
      p.pageNo = this.pageNo;
      p.pageSize = this.pageSize;
      if (this.type == 'label_query'){
        queryArticlesByLabelId(p).then(response=>{
          that.dataList = response.biz.rows;
          that.totalCount = response.biz.totalCount;
        });
      }else {
        queryArticles(p).then(response=>{
          that.dataList = response.biz.rows;
          that.totalCount = response.biz.totalCount;
        });
      }
    },
    changePage(no){
      this.pageNo = no;
      this.loadArticles()
    }
  }
}
</script>

<style scoped>

.page{
  text-align: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.ivu-page{
  width: 100%;
}

 a{
  text-decoration: none;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  font-size: 100%;
  color: #515a6e;
}
.show-list-div{
  min-height: 680px;
  width: 100%;
}
</style>