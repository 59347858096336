<template>
  <Carousel :autoplay-speed="displaySpeed" :autoplay="true"  v-model="currentValue" arrow="never" @on-change="change" :loop="true" :radius-dot="false">
    <CarouselItem v-for="(item,index) in carouselList" :key="index" v-show="itemShow">
      <div class="fix-img" :style="{'background':'url('+item.imageUrl+') no-repeat'}">
        <div class="mask">
          <transition name="slide-fade">
            <div class="show-text" style="line-height: 20px">
              <div class="title-text">
                <a class="link" :href="'/article.html?id='+item.id"> {{ item.title }}</a>
              </div>
              <div class="content-text">{{ item.desc }}</div>
            </div>
          </transition>
        </div>
      </div>
    </CarouselItem>
  </Carousel>
</template>

<script>
import {queryArticles} from '@/api/api';

export default {
  name: "ShowSwiper",
  computed: {},
  data() {
    return {
      arrowClass: 'bigArrow',
      itemShow:[],
      carouselList: [],
      currentValue: 0,
      displaySpeed: 3000
    };
  },
  mounted() {
    this.loadLifeArticle();
  },
  methods: {
    change: function (o, v) {
      let self = this;
      self.$set(self.itemShow, o, false);
      self.$set(self.itemShow, v, true);
    },
    loadLifeArticle() {
      let param = {
        pageNo: 1,
        pageSize: 10,
        channel: 2
      };
      queryArticles(param).then(response => {
        this.carouselList = response.biz.rows;
      });
      for (let i = 0,len=this.carouselList.length;i < len;i++){
        this.itemShow[i] = (i === 0);
      }
    }
  }
}
</script>

<style scoped>

.fix-img {
  width: 100%;
  height: 300px;
  line-height: 300px;
  overflow: hidden;
  background-size: cover !important;
}

.mask {
  background: rgba(49, 52, 57, 0.48);
  opacity: 0.8;
  overflow: hidden;
  height: 300px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px;

}


.ivu-carousel-item {
  height: 100%;
  overflow: visible;
}

.ivu-carousel {
  top: 0px;
}

.show-text {
  font-size: 20px;
  color: white !important;
  top: 200px;
}
.title-text {
  font-size: 25px !important;
  line-height: 25px;
  margin-bottom: 10px;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* 这里是超出几行省略 */
  overflow: hidden;

}
.title-text a{
  text-decoration: none;
}

.content-text {
  font-size: 14px;
  margin-bottom: 20px;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* 这里是超出几行省略 */
  overflow: hidden;
}

.ivu-carousel-list {
  text-align: center;
  vertical-align: middle;
}

/* 可以设置不同的进入和离开动画 */
/* 设置持续时间和动画函数 */
.slide-fade-enter-active {
  transition: all 1.5s ease;
}

.slide-fade-leave-active {
  transition: all 1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active for below version 2.1.8 */
{
  transform: translateY(-180px);
  opacity: 0;
}

a.link {
  color: #ffffff;
}

button:hover,
.button:hover {
  box-shadow: inset 0 0 0 1px #2ebaae;
  color: #2ebaae !important;
}

</style>
